import {useParams} from "react-router-dom";

type iParams = {
    documentID: string;
    projectID: string;
    anonymizationID: string;
}

export const usePageParams = () => useParams<iParams>();
export default usePageParams;

import {useAppSelector} from "@root/Hooks";
import {ColoredPdfNode} from "@root/Types/AnonymizationPdf";

type useGetNodesForPrintProps = {
    pageIndex: number,
    deletedNodes: string[]
}

type getDataForRenderProps = Omit<ColoredPdfNode, "id"> & {
    id?: ColoredPdfNode["id"]
}

const getDataForPrint = (node: getDataForRenderProps) => ({
    id: node.id ? node.id : `${node.x1},${node.x2},${node.y1},${node.y2},${node.page_number}`,
    page_number: node.page_number,
    x: node.x1,
    y: node.y1, 
    width: node.x2 - node.x1,
    height: node.y2 - node.y1
});

const useGetNodesForPrint = (props: useGetNodesForPrintProps) => {
    const {pageIndex, deletedNodes} = props;
    const changedColoredPdfNodes = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesState);
    const coloredPdfInfo = useAppSelector((state) => state.Anonymize.coloredPdfInfo.data);
    
    if (!coloredPdfInfo) return [];

    const nodesFromServerToPrint = coloredPdfInfo.anonymization_pdf_coordinates
        .filter((serverNode) => serverNode.page_number === pageIndex + 1)
        .filter((pagesServerNode) => !deletedNodes.includes(pagesServerNode.id))
        .map((notDeletedPagesServerNode) => getDataForPrint(notDeletedPagesServerNode));

    const createdNodesToPrint = changedColoredPdfNodes.to_add
        .filter((createdNode) => createdNode.page_number === pageIndex + 1)
        .map((pagesCreatedNode) => getDataForPrint(pagesCreatedNode));

    return [...nodesFromServerToPrint, ...createdNodesToPrint];
};
    
export default useGetNodesForPrint;

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";

const ChangeColoredPdfNodes = function* (action: PayloadAction<iActions.changeColoredPdfNodes>) {
    const {onSuccess, ...params} = action.payload;
    try {
        yield* call(Api.Anonymize.changeColoredPdfNodes, params);
        yield* put(Actions.Anonymize._changeColoredPdfNodesSuccess());
        onSuccess && onSuccess();
    } catch (error) {
        yield* put(Actions.Anonymize._changeColoredPdfNodesError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.changeColoredPdfNodes, ChangeColoredPdfNodes);
}

import React from "react";
import {MenuModuleItem} from "@root/Components/Controls/DropdownMenu/Modules/MenuModule/MenuModule";
import {Button, DropdownMenu as DropdownMenuDefault} from "@components/Controls";
import DownloadIcon from "@assets/Icons/DropdownMenu/Download";
import DownloadOriginIcon from "@assets/Icons/DropdownMenu/DownloadOrigin";
import ExportIcon from "@root/Assets/Icons/ExportIcon/ExportIcon";

type DropdownMenuProps = {
    downloadResult: Function,
    downloadOriginal: Function,
}

const DropdownMenu = (props: DropdownMenuProps) => {
    const dropDownMenuData: Array<MenuModuleItem> = [
        {
            icon: <DownloadIcon />,
            title: "Скачать результат",
            function: props.downloadResult,
        },
        {
            icon: <DownloadOriginIcon />,
            title: "Скачать оригинал",
            function: props.downloadOriginal,
        }
    ];
    return (
        <DropdownMenuDefault
            menuItems={dropDownMenuData}
            icon={
                <ExportIcon />
            }
            customButton={
                <Button 
                    type="link" 
                    onClick={(e) => e.stopPropagation()}
                    icon={<ExportIcon />} />
            }
            tooltipPlacement="bottom"
            tooltip="Скачать" />
    );
};

export default DropdownMenu;

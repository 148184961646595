import {call, put, delay, fork, take, cancel} from "typed-redux-saga";
import Actions from "@actions";
import Api from "../../Api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const GetTemplates = function* () {
    const notification = useNotification();

    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            while (true) {
                const {data} = yield* call(Api.Templates.getTemplates);
                yield* put(Actions.Templates._getTemplatesSuccess(data));
                yield* delay(10000);
            }
        } catch (error: any) {
            queryCount += 1;
            yield* delay(1000);
            if (queryCount === 4) {
                yield* put(Actions.Templates._getTemplatesError());
                notification({
                    type: "error",
                    message: error.response.data.error
                        ? error.response.data.error
                        : "При получении списка шаблонов произошла ошибка"
                });
            }
        }
    }
    
};

export default function* (): any {
    while (true) {
        yield* take(Actions.Templates.getTemplates);
        const bgLoopTask = yield* fork(GetTemplates);
        yield* take(Actions.Templates.stopGetTemplates);
        yield* cancel(bgLoopTask);
    }
}

import {takeEvery, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Auth/types";
import {decode, jwtToken} from "@utils/JWT/decode";
import Cookies from "universal-cookie";
import useNotification from "@root/Hooks/useNotification/useNotification";
import {Api as ApiTypes} from "@root/Types";

const cookies = new Cookies();

const UserLogin = function* (action: PayloadAction<iActions.userLogin>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        const {data} = yield* call(Api.Auth.login, payload);
        if (!data) throw new Error("Ошибка авторизации");
        const decodedAccess: jwtToken = decode(data.access_token);
        yield* call(
            {
                context: cookies,
                fn: cookies.set,
            },
            "access_token",
            data.access_token,
            {
                // TODO Пока нет SSL сертификата, убираю это
                // secure: true,
                expires: new Date(decodedAccess.exp * 1000),
                path: "/",
            }
        );
        yield* put(Actions.User.getUserInfo());
        yield* put(Actions.Auth._userLoginSuccess());
        action.payload.redirect();
    } catch (error: any) {
        const errorData = error.response.data as ApiTypes.ErrorResponse;
        if (errorData) {
            notification({
                type: "error",
                message: errorData.detail.message,
            });
            yield* put(Actions.Auth._userLoginError({
                message: errorData.detail.message,
            }));
        } else {
            notification({
                type: "error",
                message: "При логине произошла ошибка"
            });
            yield* put(Actions.Auth._userLoginError({
                message: "При логине произошла ошибка"
            }));
        }
    }
};

export default function* () {
    yield* takeEvery(Actions.Auth.userLogin, UserLogin);
}

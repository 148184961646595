export default {
    anonymize: "/v1/anonymization/start",
    anonymizeVerification: "v1/anonymization/request/{anonymization_request_id}/reanonymize",
    getAnonHtml: "v1/anonymization/{docId}",
    downloadAnonymizeDocumentsResult: "v1/anonymization/downloads",
    downloadColoredPdf: "/docs/v1/download_anon_pdf/{docId}/",
    getColoredPdf: (document_id: string) => `v1/documents/${document_id}/pdf`,
    changeColoredPdfNodes: (anonymized_document_id: string)  => `v1/anonymization/anonymized-documents/${anonymized_document_id}/coordinates`,
    getColoredPdfInfo: (anonymized_document_id: string) => `v1/anonymization/${anonymized_document_id}/pdf`,
    getAnonType: (anonymized_document_id: string) => `v1/anonymization/anonymized-documents/${anonymized_document_id}/type`
};

import rootSagaCreator from "../rootSagaCreator";
import GetPdfDocumentSaga from "./GetPdfDocumentSaga";
import DownloadOriginalDocument from "./DownloadOriginalDocumentSaga";
import getAnonymizedDocuments from "./getAnonymizedDocuments";
import DeleteAnonymizedDocuments from "./DeleteAnonymizedDocuments";

export default function* rootSaga() {
    yield rootSagaCreator([
        GetPdfDocumentSaga,
        DownloadOriginalDocument,
        getAnonymizedDocuments,
        DeleteAnonymizedDocuments
    ], "DOCUMENT");
}

import {Stage} from "konva/lib/Stage";
import {useEffect} from "react";

type cursorRectProps = {
    editMode: boolean,
    stageRef: React.RefObject<Stage>,
    isRectSelected: boolean
}

const cursorRect = (props: cursorRectProps) => {
    const {editMode, stageRef, isRectSelected} = props;
    useEffect(() => {
        if (editMode && stageRef.current && isRectSelected) {
            stageRef.current.container().style.cursor = "grab";
        }
    }, [isRectSelected]);
    return {
        onMouseEnter: () => {
            if (!editMode || !stageRef.current) return;
            if (isRectSelected) {
                stageRef.current.container().style.cursor = "grab";
                return;
            }
            stageRef.current.container().style.cursor = "pointer";
        },
        onMouseLeave: () => {
            if (!editMode || !stageRef.current) return;
            stageRef.current.container().style.cursor = "default";
        }
    };
};

export default cursorRect;

import React from "react";
import styles from "./TableToolBar.module.less";
import SearchBar from "../SearchBar/SearchBar";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import {Buttons} from "./Modules/Buttons";
import AnonymizeButton from "./Modules/AnonymizeButton/AnonymizeButton";
import ColumnsFilter from "@components/ColumnsFilter/ColumnsFilter";
import useColumns from "../../Content/DocumentsTable/Hooks/useColumns";
import usePresetColumnsFilter from "./Hooks/usePresetColumnsFilter";
import Actions from "@actions";
import useLocalStorage from "@root/Hooks/useLocalStorage/useLocalStorage";
import AnonymizeDo from "@root/Modules/AnonymizeDo/AnonymizeDo";
import {useParams} from "react-router-dom";
import {iActions} from "@root/Redux/Project/types";

type iParams = {
    projectID: string
}

export const TableToolBar  = () => {
    usePresetColumnsFilter();
    const {projectID} = useParams<iParams>();
    const anonymizeModalVisible = useAppSelector((state) => state.Anonymize.anonymizeModal.visible);
    const selectedDocument = useAppSelector((state) => state.Project.selectedDocuments);
    const selectedDocumentsIds = selectedDocument.map((document) => document.id);
    const documents = useAppSelector((state) => state.Project.documents.data);
    const documentsFetching = useAppSelector((state) => state.Project.documents.fetching);
    const selectedDocumentBoolean = (selectedDocument && selectedDocument?.length !== 0) ? true : false;
    const notSuccessDocumentSelected = (selectedDocument.filter((document) => document.overall_status !== 200 || document.overall_status !== 200).length !== 0);
    const deletedColumns = useAppSelector((state) => state.Common.deletedDocumentsColumns) || [];
    const [_, setDeletedDocumentsColumns] = useLocalStorage<string[], string>([
        "Описание",
        "Дата изменения документа",
        "Дата создания документа"
    ], "deleted-documents-columns");
    const columnsTitles = useColumns()
        .map((item) => (typeof item.title === "string" ? item.title : ""))
        .filter((item) => item !== "" && item !== "Название документа");

    const dispatch = useAppDispatch();

    const setColumns = (values: string[]) => {
        dispatch(Actions.Common.setDocumentsDeletedColumns(values));
        setDeletedDocumentsColumns(values);
    };

    const onCancel = () => {
        dispatch(Actions.Anonymize.closeAnonymizeModal());
    };

    const resetTakeDocumentsLoop = () => {
        if (documents) {
            const resetParams: iActions.getDocuments = {
                page: documents?.page,
                project_id: documents?.id,
                size: documents?.size,
            };
            documents?.order_by && (resetParams.order_by = documents?.order_by);
            documents?.name && (resetParams.name = documents?.name);
            dispatch(Actions.Project.stopGetDocuments());
            dispatch(Actions.Project.getDocuments(
                resetParams
            ));
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}> 
                {selectedDocumentBoolean && <Buttons/>} 
                {documents && documents.documents.length !== 0 
                    ? <ColumnsFilter
                        filters={columnsTitles}
                        dispatch={setColumns}
                        deletedColumns={deletedColumns}/> 
                    : <></>
                }
                <AnonymizeDo anonymizeDoButton={
                    <AnonymizeButton 
                        selectedDocumentBoolean={selectedDocumentBoolean}
                        notSuccessDocumentSelected={notSuccessDocumentSelected} />
                }
                documentsIds={selectedDocumentsIds}
                projectIds={Number(projectID)}
                anonymizeModalOpen={anonymizeModalVisible}
                onSuccessAction={() => {
                    resetTakeDocumentsLoop();
                    onCancel();
                }}
                onCancelModal={onCancel}/>
            </div>
            <div className={styles.search}>
                <SearchBar loading={documentsFetching}/>
            </div>
        </div>);
};

import {takeLatest, call} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Document/types";
import Api from "@api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DownloadOriginalDocument = function* (action: PayloadAction<iActions.downloadOriginalDocument>) {
    const {payload} = action;
    const notification = useNotification();

    const removeQuotesFilename = (filename: string) => {
        return filename.replace(/^['"]|['"]$/g, "");
    };

    try {
        const response = yield* call(Api.Document.downloadOriginalDocument, payload);
        const data = response.data;
        if (!data) throw new Error("Ошибка скачивания документа");
        const docType = response.headers["content-type"];
        const blob = new Blob([data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        if (docType === "application/zip" || docType === "application/x-zip-compressed") {
            link.setAttribute("download", "Original_documents.zip");
        } else {
            const filename = response.headers["content-disposition"]
                .replace("attachment; filename*=utf-8''", "");
            const correctFilename = removeQuotesFilename(decodeURIComponent(filename));
            link.setAttribute("download", correctFilename);
        }
        document.body.appendChild(link);
        link.click();
        notification({
            type: "info",
            message: payload.documents_ids.length > 1 ? "Оригинальные документы скачаны" : "Оригинальный документ скачан"
        });
    } catch (error) {
        notification({
            type: "error",
            message: "При скачивании документов произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Document.downloadOriginalDocument, DownloadOriginalDocument);
}

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const ConfirmVerification = function* (action: PayloadAction<iActions.confirmVerification>) {
    const {payload} = action;
    const notification = useNotification();
    yield* put(Actions.HTML.setSendEditingStatus("loading"));
    try {
        yield* call(Api.Anonymize.anonymizeVerification, {
            anonymization_request_id: payload.anonymization_request_id,
            to_delete: payload.to_delete,
            to_add: payload.to_add
        });
        // В зависимости от типа док-а *простой документ/документ внутри задания* - вызываем соответствующее обновление)
        yield* put(Actions.HTML.setIsFrameLoaded(false));
        yield* put(Actions.Project.getDocumentInfo({id: payload.id}));
        yield* put(Actions.HTML.setEditMode(null));
        yield* put(Actions.Anonymize.getAnonHtml({
            docId: payload.anonymized_document_id
        }));
        yield* put(Actions.HTML.setSendEditingStatus("success"));
    } catch (error) {
        yield* put(Actions.HTML.setSendEditingStatus("error"));
        yield* put(Actions.HTML.setIsFrameLoaded(true));
        notification({
            type: "error",
            message: "Ошибка при сохранении результатов редактирования"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.confirmVerification, ConfirmVerification);
}

import React, {PropsWithChildren} from "react";
import {Button as DefaultButton} from "@root/Components/Controls";
import styles from "./Button.module.less";

export type ButtonProps = PropsWithChildren<{ 
    onClick?: () => void,
    icon: React.ReactNode,
    disabled?: boolean,
}>

const Button = (props: ButtonProps) => {
    return (
        <div className={styles.buttonStylesOverride}>
            <DefaultButton
                className={styles.button}
                size="large"
                onMouseDown={(event) => event.preventDefault()}
                onClick={props.onClick}
                disabled={props.disabled}
                icon={props.icon}>
                {props.children}
            </DefaultButton>
        </div>
    );
};

export default Button;

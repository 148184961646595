import React from "react";
import styles from "./PageWrapper.module.less";
import {children} from "@root/Types/App";

type PageWrapperProps = {
    children: children,
    pageIndex: number,
}

const PageWrapper = (props: PageWrapperProps) => {
    const {pageIndex, children} = props;
    return (
        <div 
            data-id="page-item" 
            data-number={pageIndex} 
            className={styles.page}>
            {children}
        </div>
    );
};

export default PageWrapper;

import React from "react";
import TopToolbar from "../../Modules/TopToolbar/TopToolbar";
import HTML from "../../Modules/HTML/HTML";
import {useAppSelector} from "@root/Hooks";
import PdfView from "@root/Modules/AnonPdfView/PdfView";
import {AnonymizationTypes} from "@root/Types/Anonymization";

type ContentProps = {
    anonType: AnonymizationTypes | undefined
}

const Content = (props: ContentProps) => {
    if (props.anonType === "pdf_anonymization") return <PdfView />;
    return <HTML />;
};

const Anonymization = () => {
    const anonType = useAppSelector((state) => state.Anonymize.anonType.data?.anonymization_type);

    return (
        <>
            <TopToolbar />
            <Content anonType={anonType} />
        </>
    );
};

export default Anonymization;

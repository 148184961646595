import {takeLatest, call, put, delay} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Projects/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const getSelectedProject = function* (action: PayloadAction<iActions.getSelectedProject>) {
    const {payload} = action;
    const notification = useNotification();

    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data} = yield* call(Api.Project.getProjectInfo, payload);
            yield* put(Actions.Projects._getSelectedProjectSuccess(data));
            break;
        } catch (error) {
            yield* delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield* put(Actions.Projects._getSelectedProjectError());
                notification({
                    type: "error",
                    message: "При загрузке проекта произошла ошибка"
                });
            }
        }
    }
};

export default function* () {
    yield* takeLatest(Actions.Projects.getSelectedProject, getSelectedProject);
}

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Projects/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const setFavorite = function* (action: PayloadAction<iActions.setFavorite>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Project.setFavorite, payload);
        yield* put(Actions.Projects._setFavoriteSuccess(payload));
    } catch (error) {
        yield* put(Actions.Projects._setFavoriteError());
        notification({
            type: "error",
            message: "При добавлении проекта в избранное произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Projects.setFavorite, setFavorite);
}

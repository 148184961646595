import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";

const GetAnonType = function* (action: PayloadAction<iActions.getAnonType>) {
    try {
        const {data} = yield* call(Api.Anonymize.getAnonType, action.payload);
        yield* put(Actions.Anonymize._getAnonTypeSuccess(data));
    } catch (error) {
        yield* put(Actions.Anonymize._getAnonTypeError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.getAnonType, GetAnonType);
}

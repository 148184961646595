import React from "react";

const Error = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7092 5.52905C10.7092 5.4603 10.6529 5.40405 10.5842 5.40405L9.55291 5.40874L7.99978 7.2603L6.44822 5.4103L5.41541 5.40562C5.34666 5.40562 5.29041 5.4603 5.29041 5.53062C5.29041 5.5603 5.30134 5.58843 5.32009 5.61187L7.35291 8.03374L5.32009 10.4541C5.30121 10.477 5.29074 10.5056 5.29041 10.5353C5.29041 10.6041 5.34666 10.6603 5.41541 10.6603L6.44822 10.6556L7.99978 8.80405L9.55134 10.6541L10.5826 10.6587C10.6513 10.6587 10.7076 10.6041 10.7076 10.5337C10.7076 10.5041 10.6967 10.4759 10.6779 10.4525L8.64822 8.03218L10.681 5.6103C10.6998 5.58843 10.7092 5.55874 10.7092 5.52905Z" fill="#F86262"/>
            <path d="M8 1C4.13437 1 1 4.13437 1 8C1 11.8656 4.13437 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13437 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z" fill="#F86262"/>
        </svg>
    );
};

export default Error;

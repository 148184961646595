import {call, put, delay, take, fork, cancel} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";

const GetEntities = function* () {
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data} = yield* call(Api.Common.getEntities);
            yield* put(Actions.Common._getEntitiesSuccess(data));
            break;
        } catch (error) {
            yield* delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield* put(Actions.Common._getEntitiesError());
            }
        }
    }
};

export default function* ():any  {
    while (true) {
        yield* take(Actions.Common.getEntities);
        const bgLoopTask = yield* fork(GetEntities);
        yield* take(Actions.Common.stopGetEntities);
        yield* cancel(bgLoopTask);
    }
}

import React from "react";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import LoadingIcon from "./Icons/LoadingIcon";
import styles from "./LoadingBadge.module.less";

type LoadingBadgeProps = {
    type?: "document" | "anonymization"
}

const LoadingBadge = ({type = "document"}: LoadingBadgeProps) => {
    return (
        <Tooltip 
            title={type === "document" ? "Загрузка выполняется" : "В процессе"}> 
            <span className={styles.badge}>
                <LoadingIcon />
            </span>
        </Tooltip>
    );
};

export default LoadingBadge;

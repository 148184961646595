import React from "react";
import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "../../Api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Project/types";
import {Project} from "@root/Api/ProjectApi/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

export const getDocumentRequest = function* (params: Project.iGetDocumentInfo) {
    try {
        yield* put(Actions.HTML.setIsFrameLoaded(false));
        const {data} = yield* call(Api.Project.getDocumentInfo, params);
        return data;
    } catch (ex) {
        return null;
    }
};

const GetDocumentInfo = function* (action: PayloadAction<iActions.getDocumentInfo>) {
    const notification = useNotification();
    const {payload} = action;
    const data = yield* call(getDocumentRequest, {id: payload.id});

    if (data) {
        yield* put(Actions.Project._getDocumentInfoSuccess(data));
    } else {
        yield* put(Actions.Project._getDocumentInfoError());
        notification({
            type: "error",
            message: "При получении документа произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Project.getDocumentInfo, GetDocumentInfo);
}

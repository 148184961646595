import React from "react";
import styles from "./ButtonsBlock.module.less";
import ButtonChangeMode, {Type} from "../ButtonChangeMode/ButtonChangeMode";

type ButtonsBlockProps = {
    type: Type,
    startEditing: (type: Type) => void,
    editingDisable: boolean;
    isEditing: boolean
}

const ButtonsBlock = (props: ButtonsBlockProps) => {
    const {type, isEditing, editingDisable, startEditing} = props;

    return (
        <div className={styles.wrapper}>
            <ButtonChangeMode 
                type={type}
                onClick={() => startEditing(type)}
                isEditing={isEditing}
                tooltip={"Редактирование"}
                disabled={editingDisable}/>
        </div>
    );
};

export default ButtonsBlock;

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@root/Redux/Templates/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const CreateTemplate = function* (action: PayloadAction<iActions.createTemplate>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Templates.createTemplates, payload);
        yield* put(Actions.Templates.stopGetTemplates());
        yield* put(Actions.Templates.getTemplates());
        yield* put(Actions.Templates._createTemplateSuccess());
        notification({
            type: "info",
            message: "Шаблон создан"
        });
    } catch (error) {
        yield* put(Actions.Templates._createTemplateError());
        notification({
            type: "error",
            message: "При создании шаблона произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Templates.createTemplate, CreateTemplate);
}

import {call, put, delay, fork, take, cancel} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Project/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

type getDocumentsAction = ReturnType<typeof Actions.Project.getDocuments>

const DocumentsGet = function* (action: PayloadAction<iActions.getDocuments>) {
    const {payload} = action;
    const notification = useNotification();
    
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            while (true) {
                const {data} = yield* call(Api.Project.getDocuments, payload);
                yield* put(Actions.Project._getDocumentsSuccess(data));
                yield* delay(10000);
            }
        } catch (error) {
            queryCount += 1;
            yield* delay(1000);
            if (queryCount === 4) {
                yield* put(Actions.Project._getDocumentsError());
                notification({
                    type: "error",
                    message: "При загрузке документов произошла ошибка"
                });
            }
        }
    }
};

export default function* ():any {
    while (true) {
        const action: getDocumentsAction = yield* take(Actions.Project.getDocuments);
        const bgLoopTask = yield* fork(DocumentsGet, action);
        yield* take(Actions.Project.stopGetDocuments);
        yield* cancel(bgLoopTask);
    }
}

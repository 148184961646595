import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Auth/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

import {Api as ApiTypes} from "@root/Types";

const UserRegistration = function* (action: PayloadAction<iActions.userRegistration>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Auth.registration, payload);
        yield* put(Actions.Auth._userRegistrationSuccess());
        action.payload.redirect();
        notification({
            type: "info",
            message: "Вы успешно зарегистрированы"
        });
    } catch (error: any) {
        const errorData = error.response.data as ApiTypes.ErrorResponse;
        if (errorData) {
            notification({
                type: "error",
                message: errorData.detail.message,
            });
            yield* put(Actions.Auth._userRegistrationError({
                message: errorData.detail.message,
            }));
        } else {
            notification({
                type: "error",
                message: "При регистрации произошла ошибка"
            });
            yield* put(Actions.Auth._userRegistrationError({
                message: "При регистрации произошла ошибка"
            }));
        }
    }
};

export default function* () {
    yield* takeLatest(Actions.Auth.userRegistration, UserRegistration);
}

import React, {useEffect, useRef, useState} from "react";
import {ColoredPdfNodeForRender} from "@root/Types/AnonymizationPdf";
import {Stage} from "konva/lib/Stage";
import {Image as KonvaImage, Rect, Transformer,} from "react-konva";
import getScaledVariablesForPrint from "../../Utils/getScaledVariablesForPrint";
import {Box, Transformer as TransformerType} from "konva/lib/shapes/Transformer";
import cursorPointer from "./Utils/cursorPointer";
import Konva from "konva";
import {Vector2d} from "konva/lib/types";
import {KonvaEventObject} from "konva/lib/Node";
import cursorGrab from "./Utils/cursorGrab";
import DeleteIcon from "@assets/Icons/AnonymizeDelete/onDeleteButton.svg";

type ResizableRectProps = {
    item: ColoredPdfNodeForRender,
    scale: number,
    stageRef: React.RefObject<Stage>,
    editMode: boolean,
    selectColoredNode: (id: string | null) => void,
    selectedColoredNodeID: string | null,
    updateColoredNode: (item: ColoredPdfNodeForRender) => void,
    onDelete: (id: string) => void,
}

const ResizableRect = (props: ResizableRectProps) => {
    const {
        item, 
        scale, 
        stageRef,
        editMode, 
        onDelete, 
        selectColoredNode, 
        selectedColoredNodeID, 
        updateColoredNode, 
    } = props;
    const imageRef = useRef<Konva.Image | null>(null);
    const rectRef = useRef<Konva.Rect | null>(null);
    const trRef = useRef<TransformerType | null>(null);
    const [actionButtonImage, setActionButtonImage] = useState<null | HTMLImageElement>(null);
    const [actionButtonX, setActionButtonX] = useState<number>(0);
    const [actionButtonY, setActionButtonY] = useState<number>(0);
    const isRectSelected = item.id === selectedColoredNodeID ? true : false;
    const {x, y, width} = getScaledVariablesForPrint({
        item, 
        scale
    });

    const onSelectColoredNode = (event: KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        if (!editMode) return;
        selectColoredNode(item.id);
        rectRef.current?.moveToTop();
        trRef.current?.moveToTop();
        imageRef.current?.moveToTop();
        setActionButtonX(x + width - 25);
        setActionButtonY(y + 5);
    };

    const resizeRect = (oldRect: Box, newRect: Box) => {
        const stage = stageRef.current;
        if (!stage) return oldRect;
        if (Math.abs(newRect.width) < 5 || Math.abs(newRect.height) < 5) return oldRect;
        if (newRect.x < 0 || newRect.y < 0 || newRect.x + newRect.width > stage.width() || newRect.y + newRect.height > stage.height()) return oldRect;
        setActionButtonX(newRect.x + newRect.width - 25);
        setActionButtonY(newRect.y + 5);
        return newRect;
    };

    const updateNode = () => {
        if (!rectRef.current) return;
        const scaleX = rectRef.current.scaleX();
        const scaleY = rectRef.current.scaleY();
        // height / weight элемента при масштабировании не изменяются - изменяются значения scaleX / scaleY
        // сброс scale до 1 позволяет в дальнейшем избежать искажений связанных с высчитыванием скейла от скейла
        rectRef.current.scaleX(1);
        rectRef.current.scaleY(1);
        updateColoredNode({
            id: item.id,
            height: rectRef.current.height() * scaleY,
            width: rectRef.current.width() * scaleX,
            x: rectRef.current.x(),
            y: rectRef.current.y(),
            page_number: item.page_number
        });
    };
    
    const deleteNode = (event: KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        onDelete(item.id);
    };

    const onDragRect = (pos: Vector2d) => {
        if (!stageRef.current || !rectRef.current) return pos;
        const stageWidth = stageRef.current.width();
        const stageHeight = stageRef.current.height();
        const elementWidth = rectRef.current.width();
        const elementHeight = rectRef.current.height();
        let x = pos.x;
        let y = pos.y;

        if (pos.x < 0) {
            x = 0;
        }
        if (pos.x > stageWidth - elementWidth) {
            x = stageWidth - elementWidth;
        }
        if (pos.y < 0) {
            y = 0;
        }
        if (pos.y > stageHeight - elementHeight) {
            y = stageHeight - elementHeight;
        }

        setActionButtonX(x + elementWidth - 25);
        setActionButtonY(y + 5);  
              
        return {
            x,
            y
        };
    };

    useEffect(() => {
        setActionButtonX(x + width - 25);
        setActionButtonY(y + 5);
        const img = new Image();
        img.src = DeleteIcon;
        img.onload = () => setActionButtonImage(img);
    }, [selectedColoredNodeID]);

    useEffect(() => {
        if (!editMode || !rectRef.current || !trRef.current || !trRef.current) return;
        if (isRectSelected) trRef.current.nodes([rectRef.current]);
        else trRef.current.detach();
        const layer = trRef.current.getLayer();
        if (layer) layer.batchDraw();
    }, [editMode, isRectSelected]);

    return (
        <>
            <Rect
                ref={rectRef}
                {...getScaledVariablesForPrint({
                    item, 
                    scale
                })}
                {...cursorGrab({
                    editMode,
                    stageRef,
                    isRectSelected
                })}
                draggable={isRectSelected}
                onTransformEnd={updateNode}
                dragBoundFunc={onDragRect}
                onDragEnd={updateNode}
                cornerRadius={0}
                onClick={onSelectColoredNode}
                fill={"#000"}/>
            <Transformer 
                ref={trRef}
                flipEnabled={false}
                rotateEnabled={false}
                boundBoxFunc={resizeRect}
                onTransformEnd={updateNode}
                visible={isRectSelected}/>
            <KonvaImage 
                ref={imageRef} 
                image={actionButtonImage ?? undefined} 
                x={actionButtonX} 
                y={actionButtonY} 
                width={20} 
                height={20} 
                visible={isRectSelected}
                onClick={deleteNode} 
                {...cursorPointer({
                    editMode,
                    stageRef,
                })}/>
        </>
    );
};

export default ResizableRect;

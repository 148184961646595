import Actions from "@actions";
import {takeLatest, call, put} from "typed-redux-saga";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@root/Redux/Project/types";
import Api from "@root/Api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteDocuments = function* (action: PayloadAction<iActions.DeleteDocuments>) {
    const {documents_ids, onSuccess} = action.payload;
    const notification = useNotification();
    
    try {
        yield* call(Api.Project.deleteDocuments, {documents_ids});
        yield* put(Actions.Project.eraseSelectedDocuments());
        onSuccess && onSuccess();
        notification({
            type: "info",
            message: "Документы успешно удалены"
        });
    } catch (error) {
        notification({
            type: "error",
            message: "При удалении документов произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Project.deleteDocuments, DeleteDocuments);
}

import {Layer} from "konva/lib/Layer";
import {useEffect} from "react";
import {PDFPageProxy} from "react-pdf";

type useRenderCanvasProps = {
    page: PDFPageProxy | null,
    layerDocRef: React.RefObject<Layer>,
    setWidth: (value: React.SetStateAction<number>) => void,
    setHeight: (value: React.SetStateAction<number>) => void
}

const useRenderCanvas = (props: useRenderCanvasProps) => {
    const {page, layerDocRef, setHeight, setWidth} = props;
    useEffect(() => {
        // Рендерим канвас
        if (!page || !layerDocRef.current) return;
        const viewport = page.getViewport({scale: 1.35});
        const context = layerDocRef.current.getContext();
        setWidth(viewport.width);
        setHeight(viewport.height);
        page.render({
            canvasContext: context,
            viewport,
        });
    }, [page]);
};

export default useRenderCanvas;

import {takeLatest, call, put, delay} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";

const GetLabels = function*() {
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data} = yield* call(Api.HTML.getLabels);
            yield* put(Actions.HTML._getLabelsSuccess(data));
            break;
        } catch (ex) { 
            yield* delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                yield* put(Actions.HTML._getLabelsError());
            }
        }
    }
};

export default function* () {
    yield* takeLatest(Actions.HTML.getLabels, GetLabels);
}

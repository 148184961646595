import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";
import {useNotification} from "@root/Hooks";

const AnonymizeFile = function*(action:PayloadAction<iActions.anonymizeFile>) {
    const {payload} = action;
    const {documents_ids, anonymization_type, entities, onSuccess} = payload;
    const notification = useNotification();
    try {
        yield* call(Api.Anonymize.Anonymize, {
            documents_ids,
            anonymization_type,
            entities
        });
        onSuccess && onSuccess();
        yield* put(Actions.Anonymize._anonymizeFileSuccess());
        notification({
            type: "info",
            message: documents_ids.length > 1 ? "Документы отправлены на обезличивание" : "Документ отправлен на обезличивание"
        });
    } catch (ex) {
        notification({
            type: "error",
            message: documents_ids.length > 1 ? "Произошла ошибка при отправке документов на обезличивание" : "Произошла ошибка при отправке документа на обезличивание"
        });
        yield* put(Actions.Anonymize._anonymizeFileError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.anonymizeFile, AnonymizeFile);
}

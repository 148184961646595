import {useEffect, useState} from "react";

type useGetScaleProps = {
    currentPageWidth: number,
    originalWidth: number
}

const useGetScale = (props: useGetScaleProps) => {
    const {currentPageWidth, originalWidth} = props;
    const [scale, setScale] = useState<number | null>(null);
    useEffect(() => {
        if (scale || currentPageWidth === 0 || !currentPageWidth) return;
        setScale(currentPageWidth / originalWidth);
    }, [currentPageWidth]);
    return scale || null;
};

export default useGetScale;

import {Stage} from "konva/lib/Stage";
import {useEffect} from "react";

type cursorPointerProps = {
    editMode: boolean,
    stageRef: React.RefObject<Stage>,
}

const cursorPointer = (props: cursorPointerProps) => {
    const {editMode, stageRef} = props;
    useEffect(() => {
        return () => {
            if (!editMode || !stageRef.current) return;
            stageRef.current.container().style.cursor = "default";
        };
    }, []);
    return {
        onMouseEnter: () => {
            if (!editMode || !stageRef.current) return;
            stageRef.current.container().style.cursor = "pointer";
        },
        onMouseLeave: () => {
            if (!editMode || !stageRef.current) return;
            stageRef.current.container().style.cursor = "default";
        }
    };
};

export default cursorPointer;

import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import PdfViewComponent from "./Content/PdfView";
import Actions from "@actions";

const PdfView = () => {
    const coloredPdfFile = useAppSelector((state) => state.Anonymize.coloredPdf.file.data);
    const dispatch = useAppDispatch();

    if (!coloredPdfFile) return null;

    const url = URL.createObjectURL(coloredPdfFile);

    useEffect(() => {
        dispatch(Actions.Common.getEntities());
        return () => {
            URL.revokeObjectURL(url);
            dispatch(Actions.Anonymize.eraseChangesColoredPdfNodes());
        };
    }, []);
    return (
        <PdfViewComponent url={url} />
    );
};

export default PdfView;

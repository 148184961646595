import onCancelDeleteButton from "@assets/Icons/AnonymizeDelete/onCancelDeleteButton.svg";
import onDeleteButton from "@assets/Icons/AnonymizeDelete/onDeleteButton.svg";
import {styles} from "./Styles/styles";
import getLabelStyles from "./Styles/getLabelStyles";
import {Anonymization} from "@types";

type addLabelProps = {
    lastNode: HTMLElement;
    markup: Omit<Anonymization.MarkupItem, "text">;
    workIframe: Document;
    onDelete: () => void;
    highlights: HTMLSpanElement[];
    onCancelDelete?: () => void;
}

const addLabel = (props: addLabelProps) => { 
    props.lastNode.setAttribute("id", `label${props.markup.id}`);
    props.lastNode.classList.add(`label${props.markup.id}`);

    const actionButton = document.createElement("text");
    actionButton.setAttribute("id", `${props.markup.id}`);
    actionButton.classList.add(`label${props.markup.id}`);
    // по этому классу собираются кнопки для включения их по display во время режима верификации и отключения
    actionButton.classList.add("markup_control_button");
    actionButton.style.cssText = styles.button;
    // Если маркап предсоздан кнопку скрываем до включения режима edit иначе сразу показываем тк если не сохранить они все равно пропадут
    if (props.onCancelDelete !== undefined) {
        actionButton.style.visibility = "hidden";
    } else {
        actionButton.style.visibility = "initial";
    }
    actionButton.style.backgroundImage = `url(${onDeleteButton})`;
    props.lastNode.after(actionButton);
    
    const label = getLabelStyles(
        {
            id: props.markup.id,
            labelText: props.markup.label
        }
    );

    // TODO убрать opacity и привести все к норм стилям
    // если это предразмеченный маркап делаем эффект переключения иначе только удаление
    if (props.onCancelDelete !== undefined) {
        actionButton.onclick = () => {
            if (actionButton.style.backgroundImage === `url("${onCancelDeleteButton}")`) {
                props.highlights.forEach((token) => {
                    token.style.opacity = "1";
                });
                if (props.onCancelDelete) props.onCancelDelete();
                actionButton.style.backgroundImage = `url(${onDeleteButton})`;
            } else {
                props.highlights.forEach((token) => {
                    token.style.opacity = "0.5";
                });
                props.onDelete();
                actionButton.style.backgroundImage = `url(${onCancelDeleteButton})`;
            } 
        };
    } else {
    // onclick для созданных сущностей чистим все
        actionButton.onclick = () => {
            // проходим по всем спанам маркапа и чистим с них data-label + стили (удаляем спан и вставляем на место как текст)
            // нужно тк мы ищем по data-label когда даем ошибку о наведении на уже размеченное
            props.highlights.forEach((token) => {
                token.removeAttribute("data-label");
                const text = token.textContent;
                if (text) token.after(text);
                token.remove();
                actionButton.remove();
            });
            props.onDelete();
        };
    }
    // добавляем label как псевдоэлемент к последней ноде маркапа
    const labelStyleSheet = props.workIframe.createElement("style");
    props.workIframe.head.appendChild(labelStyleSheet);
    if (labelStyleSheet.sheet) labelStyleSheet.sheet.insertRule(label);
};

export default addLabel;

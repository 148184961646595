import {call, put, delay, fork, take, cancel} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const GetLastDocuments = function* () {
    const notification = useNotification();

    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            while (true) {
                const {data} = yield* call(Api.Common.getLastDocuments);
                yield* put(Actions.Common._getLastDocumentsSuccess(data));
                yield* delay(10000);
            }
        } catch (error) {
            queryCount += 1;
            yield* delay(1000);
            if (queryCount === 4) {
                yield* put(Actions.Common._getLastDocumentsError());
                notification({
                    type: "error",
                    message: "При загрузке документов произошла ошибка"
                });
            }

        }
    }
    
};

export default function* ():any {
    while (true) {
        yield* take(Actions.Common.getLastDocuments);
        const bgLoopTask = yield* fork(GetLastDocuments);
        yield* take(Actions.Common.stopGetLastDocuments);
        yield* cancel(bgLoopTask);
    }
}

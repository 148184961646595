import rootSagaCreator from "../rootSagaCreator";
import AnonymizeWatchFlow from "./AnonymizeWatchFlow";
import AnonymizeFile from "./AnonymizeFile";
import ConfirmVerification from "./ConfirmVerification";
import GetColoredPdf from "./GetColoredPdf";
import GetAnonHtml from "./GetAnonHtml";
import DownloadAnonymizeDocumentsResultSaga from "./DownloadAnonymizeDocumentsResultSaga";
import GetColoredPdfInfo from "./GetColoredPdfInfo";
import ChangeColoredPdfNodes from "./ChangeColoredPdfNodes";
import GetAnonType from "./GetAnonType";

export default function* rootSaga() {
    yield rootSagaCreator([
        AnonymizeWatchFlow,
        AnonymizeFile,
        ConfirmVerification,
        GetColoredPdf,
        GetAnonHtml,
        DownloadAnonymizeDocumentsResultSaga,
        GetColoredPdfInfo,
        ChangeColoredPdfNodes,
        GetAnonType
    ], "ANONYMIZE");
}

import React from "react";
import {Rect} from "react-konva";

type rectSize = {
    x: number;
    y: number;
    width: number;
    height: number;
}

type ShadowRectProps = {
    rectSize: rectSize
    visible: boolean 
}

const ShadowRect = (props: ShadowRectProps) => {
    const {visible, rectSize} = props;
    const {x, y, width, height} = rectSize;
    return <Rect
        fill="#CCC"
        opacity={0.5}
        cornerRadius={4}
        x={x}
        y={y}
        visible={visible}
        width={width}
        height={height}/>;
};

export default ShadowRect;

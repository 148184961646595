import React, {useEffect} from "react";
import styles from "./Anonymization.module.less";
import {usePageParams} from "./Hooks/useParams";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@actions";
import ContentController from "./Controllers/ContentController";
import {useHistory} from "react-router-dom";
import routes from "@root/Routes/Routes";
import useTitle from "@root/Hooks/useTitle/useTitle";

const Anonymization = () => {
    const {projectID, documentID, anonymizationID} = usePageParams();
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.Anonymize.anonymizedHtml.data);
    const anonType = useAppSelector((state) => state.Anonymize.anonType.data?.anonymization_type);
    const history = useHistory();
    useTitle("DeepDocs - Анонимизация");
    
    useEffect(() => {
        if (!documentID || !anonymizationID) return;
        dispatch(Actions.Anonymize.getAnonType({anonymized_documents_ids: anonymizationID}));
        dispatch(Actions.Project.getDocumentInfo({id: documentID}));
        dispatch(Actions.Anonymize.initAnonymizedDoc({id: documentID}));
        return () => {
            dispatch(Actions.Anonymize.anonymizeWatchStop());
            dispatch(Actions.Common.setScrollPosition(null));
            dispatch(Actions.Anonymize.eraseColoredFile());
            dispatch(Actions.Anonymize.eraseAnonType());
        };
    }, []);

    // Проверка нужно ли запускать процесс перезапроса данных анонимизации для html в случае доразметки или выкидывать пользователя если разметка ранее не запускалась
    useEffect(() => {
        if (!documentID || !anonymizationID || !projectID) return; // Проверяем есть ли все нужные qs
        if (!data || anonType === "pdf_anonymization") return; // Если это не пдф и данные есть
        const {anonymization_status} = data;
        if (anonymization_status === 0) history.push(routes.lk.project.document(projectID, documentID)); // Если анонимизация не запускалась возвращаем пользователя
        if (anonymization_status === 100) dispatch(Actions.Anonymize.anonymizeWatchStart({docId: anonymizationID})); // Если анонимизация выполняется - запускаем запросы
    }, [data, anonType]);

    // Проверка какие данные подтягивать для анонимизации
    useEffect(() => {
        if (!documentID || !anonymizationID) return;
        if (anonType !== "pdf_anonymization") {
            dispatch(Actions.Anonymize.getColoredPdfInfo({anonymized_document_id: anonymizationID}));
            dispatch(Actions.Anonymize.getColoredPdf({document_id: documentID}));
        } 
        if (anonType === "dict_anonymization" || anonType === "const_anonymization") {
            dispatch(Actions.Anonymize.getAnonHtml({docId: anonymizationID}));
        }
    }, [anonType]);

    return (
        <div className={styles.wrapper}>
            <ContentController />
        </div>
    );
};

export default Anonymization;

import React from "react";
import styles from "./SwitchModeButtons.module.less";
import ButtonChangeMode, {Type} from "./Components/ButtonChangeMode/ButtonChangeMode";
import {useAppSelector} from "@root/Hooks";
import CreateTemplateButton from "@root/Pages/Lk/Templates/Components/CreateTemplateButton/CreateTemplateButton";
import {Popconfirm} from "antd";
import ButtonsBlock from "./Components/ButtonsBlock/ButtonsBlock";
import Hint from "@root/Components/Hint/Hint";
import classNames from "classnames";

type SwitchModeBarProps = {
    startEditing: (type: Type) => void,
    saveEditing: () => void,
    stopEditing: () => void
}

const SwitchModeButtons = (props: SwitchModeBarProps) => {
    const editing = useAppSelector((state) => state.Anonymize.coloredPdf.editMode);
    const changedColoredPdfNodesStatus = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesStatus);
    const changedColoredPdfNodesState = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesState);
    const isColoredNodesChanged = changedColoredPdfNodesState.to_delete.length !== 0 || changedColoredPdfNodesState.to_add.length !== 0;
    const SaveButtonTooltip = !editing ? "Необходимо зайти в режим редактирования" : "Сохранить";
    const StopButtonTooltip = !editing ? "Необходимо зайти в режим редактирования" : "Отмена";
    const onSave = () => (!changedColoredPdfNodesStatus.fetching) && props.saveEditing();
    const onStop = () => (!changedColoredPdfNodesStatus.fetching && !isColoredNodesChanged) && props.stopEditing();

    return (
        <div className={styles.switchModeBar}>
            <ButtonsBlock
                {...props} 
                type={Type.startMarkuping}
                isEditing={editing}
                editingDisable={false}/>
            <div className={styles.controlButtons}>
                <ButtonChangeMode
                    disabled={!editing}
                    type={Type.save}
                    onClick={onSave}
                    tooltip={SaveButtonTooltip}
                    isLoading={changedColoredPdfNodesStatus.fetching}/>
                <Popconfirm
                    disabled={!isColoredNodesChanged}
                    placement="bottomRight"
                    title="Сохранить изменения?"
                    onConfirm={() => props.saveEditing()}
                    onCancel={() => props.stopEditing()}
                    okText="Да"
                    cancelText="Нет">
                    <ButtonChangeMode 
                        disabled={!editing || changedColoredPdfNodesStatus.fetching}
                        onClick={onStop}
                        tooltip={StopButtonTooltip}
                        type={Type.stop}/>
                </Popconfirm>
                <Hint
                    className={classNames(styles.hint, {[styles.hintVisible]: editing})} 
                    title="Для создания закрашивания зажмите кнопку SHIFT и выделите область закрашивания" 
                    placement="right" /> 
            </div>
            <CreateTemplateButton />
        </div>
    );
};

export default SwitchModeButtons;

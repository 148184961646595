import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Anonymize/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const GetColoredPdf = function* (action: PayloadAction<iActions.getColoredPdf>) {
    const {payload} = action;
    const notification = useNotification();
    try {
        const response = yield* call(Api.Anonymize.getColoredPdf, payload);
        const data = response.data;
        const filename = response.headers["content-disposition"]
            .replace("inline; filename*=utf-8''", "")
            .replace("inline; filename=", "")
            .replaceAll("\"", "");
        yield* put(Actions.Anonymize._getColoredPdfSuccess({
            file: data,
            fileName: decodeURIComponent(filename)
        }));
    } catch (error) {
        yield* put(Actions.Anonymize._getColoredPdfError());
        notification({
            type: "error",
            message: "При загрузке закрашенного PDF файла произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.getColoredPdf, GetColoredPdf);
}

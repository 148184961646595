import React from "react";
import {takeEvery, call} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import Cookies from "universal-cookie";
import useNotification from "@root/Hooks/useNotification/useNotification";
import routes from "@root/Routes/Routes";

import {Api as ApiTypes} from "@root/Types";

const cookies = new Cookies();

const UserLogout = function* () {
    const notification = useNotification();

    try {
        yield* call(Api.Auth.logout);
        yield cookies.remove("access_token", {path: "/"});
        yield window.location.href = routes.auth.login;
    } catch (error: any) {
        const errorData = error.response.data as ApiTypes.ErrorResponse;
        if (errorData) {
            notification({
                type: "error",
                message: errorData.detail.message,
            });
        } else {
            notification({
                type: "error",
                message: "При выходе из аккаунта произошла ошибка"
            });
        }
        
    }
};

export default function* () {
    yield* takeEvery(Actions.Auth.userLogout, UserLogout);
}

import React from "react";
import Loading from "@root/Components/Loading/Loading";
import styles from "./PageLoading.module.less";

const PageLoading = () => { 
    return (
        <div className={styles.pageLoading}>
            <Loading />
        </div>
    );
};

export default PageLoading;

import React from "react";
import {useAppSelector} from "@root/Hooks";
import Loading from "@components/Loading/Loading";
import AnonymizeContent from "../Contents/Anonymization/Anonymization";
import Error from "@modules/Error/Error";

const ContentController = () => {
    const anonymizationType = useAppSelector((state) => state.Anonymize.anonType);
    const documentState = useAppSelector((state) => state.Project.openedDocument);
    // Данные для pdf
    const coloredPdfInfo = useAppSelector((state) => state.Anonymize.coloredPdfInfo);
    const pdfDocument = useAppSelector((state) => state.Anonymize.coloredPdf.file);
    // Данные для html
    const anonymizeState = useAppSelector((state) => state.Anonymize.anonymizeProcess);
    const anonymizeHtml = useAppSelector((state) => state.Anonymize.anonymizedHtml);
    const anonymizeStatus = useAppSelector((state) => state.Anonymize.anonymizedHtml.data?.anonymization_status);

    if (anonymizationType.fetching) {
        return (
            <Loading>
                Загрузка типа анонимизации...
            </Loading>
        );
    }
    if (documentState.fetching) {
        return (
            <Loading>
                Загрузка информации о документе...
            </Loading>
        );
    }
    if (anonymizationType.error || documentState.error) {
        return (
            <Error>
                Произошла ошибка при загрузке информации об анонимизации.
            </Error>
        );
    }

    // flow для анонимизации закрашиванием
    if (anonymizationType.data && anonymizationType.data.anonymization_type === "pdf_anonymization") {
        if (coloredPdfInfo.error || pdfDocument.error) {
            return (
                <Error>
                    Произошла ошибка при загрузке информации об анонимизации.
                </Error>
            );
        }
        if (coloredPdfInfo.fetching || pdfDocument.fetching) {
            return (
                <Loading>
                    Загрузка информации анонимизации...
                </Loading>
            );
        }
        return <AnonymizeContent />;
    }

    // flow для анонимизации html
    if (
        anonymizationType.data && 
        (anonymizationType.data.anonymization_type === "const_anonymization" || anonymizationType.data.anonymization_type === "dict_anonymization")
    ) {
        if (anonymizeHtml.error || anonymizeState.error || documentState.error) {
            <Error>
                Произошла ошибка при загрузке информации об анонимизации.
            </Error>;
        }
        if (anonymizeStatus === 100) {
            return (
                <Loading>
                    Выполняется анонимизация. <br />
                    Пожалуйста, подождите...
                </Loading>
            );
        }
        if (anonymizeStatus === 400) {
            return (
                <Error>
                    Произошла ошибка при анонимизации
                </Error>
            );
        }
        if (anonymizeStatus ===  200 && !anonymizationType.loaded && !anonymizationType.error) {
            return (
                <Loading>
                    Выполняется загрузка HTML файла. <br />
                    Пожалуйста, подождите...
                </Loading>
            );
        }
        if (anonymizeHtml.loaded && anonymizeHtml.data?.anonymized_html) return <AnonymizeContent />;
    }

    return null;
};

export default ContentController;

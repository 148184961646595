import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import styles from "./PdfView.module.less";
import {pdfjs} from "react-pdf/dist/esm/entry.webpack";
import {PDFDocumentProxy} from "pdfjs-dist";
import Actions from "@actions";
import PdfPage from "../Modules/PdfPage/PdfPage";
import Scrollbars from "react-custom-scrollbars";
import SwitchModeButtons from "../Modules/SwitchModeButtons/SwitchModeButtons";

type PdfViewProps = {
    url: string
}

const PdfView = (props: PdfViewProps) => {
    const coloredPdfPages = useAppSelector((state) => state.Anonymize.coloredPdf.pages);
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const changedColoredPdfNodesState = useAppSelector((state) => state.Anonymize.changedColoredPdfNodesState);
    const dispatch = useAppDispatch();
    const [result, setResult] = useState<PDFDocumentProxy | null>();

    const onSuccessChangePdf = () => {
        if (!openedDocument?.anonymized_document_id) return;
        dispatch(Actions.Anonymize.setSelectedColoredNodeID(null));
        dispatch(Actions.Anonymize.eraseChangesColoredPdfNodes());
        dispatch(Actions.Anonymize.getColoredPdfInfo({
            anonymized_document_id: openedDocument.anonymized_document_id
        }));
        dispatch(Actions.Anonymize.changeColoredPdfEditMode(false));
    };

    const buttonsProps = {
        startEditing: () => {
            dispatch(Actions.Anonymize.changeColoredPdfEditMode(true));
        },
        saveEditing: () => {
            if (!openedDocument) return;
            dispatch(Actions.Anonymize.changeColoredPdfNodes({
                to_add: changedColoredPdfNodesState.to_add,
                to_delete: changedColoredPdfNodesState.to_delete,
                anonymized_document_id: openedDocument.anonymized_document_id,
                onSuccess: onSuccessChangePdf
            }));        
        },
        stopEditing: () => {
            dispatch(Actions.Anonymize.changeColoredPdfEditMode(false));
            dispatch(Actions.Anonymize.eraseChangesColoredPdfNodes());
            dispatch(Actions.Anonymize.setSelectedColoredNodeID(null));
        }
    };

    useEffect(() => {
        if (!props.url) return;
        pdfjs
            .getDocument(props.url)
            .promise
            .then((result) => {
                setResult(result as unknown as PDFDocumentProxy);
            })
            .catch(() => {
                setResult(null);
            });
    }, []);

    useEffect(() => {
        if (!result) return;
        dispatch(Actions.Anonymize.setColoredPagesNumber(result.numPages));
    }, [result]);
    
    if (!coloredPdfPages || !result) return null;
  
    return (
        <div className={styles.grid}>
            <SwitchModeButtons {...buttonsProps}/>
            <Scrollbars>
                <div className={styles.ViewPDF}>
                    {Array(coloredPdfPages)
                        .fill("")
                        .map((_, index) => (
                            <div className={styles.page} key={index}>
                                <PdfPage 
                                    pageIndex={index} 
                                    url={props.url} 
                                    result={result}/>
                            </div>
                        ))}
                </div>

            </Scrollbars>
        </div>
    );
};

export default PdfView;

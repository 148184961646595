import Actions from "@actions";
import {takeLatest, call, put} from "typed-redux-saga";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@root/Redux/Document/types";
import Api from "@root/Api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteAnonymizedDocuments = function* (action: PayloadAction<iActions.deleteAnonymizedDocuments>) {
    const notification = useNotification();
    
    try {
        yield* call(Api.Document.deleteAnonymizedDocument, action.payload);
        notification({
            type: "info",
            message: "Документы успешно удалены"
        });
        yield* put(Actions.Document._deleteAnonymizedDocumentsSuccess(action.payload));
    } catch (error) {
        notification({
            type: "error",
            message: "При удалении документов произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Document.deleteAnonymizedDocuments, DeleteAnonymizedDocuments);
}

import {takeLatest, call, put, delay} from "typed-redux-saga";
import Actions from "@actions";
import {useNotification} from "@root/Hooks";
import Api from "@api";

const notification = useNotification();

const GetProjects = function* () {
    let queryCount: number = 0;
    while (queryCount < 5) {
        try {
            const {data} = yield* call(Api.Projects.getProjects);
            yield* put(Actions.Projects._getProjectsSuccess(data));
            break;
        } catch (error) {
            yield* delay(1000);
            queryCount += 1;
            if (queryCount === 4) {
                notification({
                    message: "При получении списка проектов произошла ошибка",
                    type: "error"
                });
                yield* put(Actions.Projects._getProjectsError());
            }
        }
    }
};

export default function* () {
    yield* takeLatest(Actions.Projects.getProjects, GetProjects);
}

import {iParams} from "./types";
import Request from "../Request";
import paths from "./endpoints";

export default {
    Anonymize: (params: iParams.Anonymize) => {
        const url =  paths.anonymize;
        return Request.post(
            url,
            params
        );
    },
    anonymizeVerification: (params: iParams.iAnonymizeVerification) => {
        const url = paths.anonymizeVerification.replace("{anonymization_request_id}", params.anonymization_request_id);
        return Request.post<void>(url, params);
    },
    getAnonHtml: (props: iParams.iGetAnonHtml) => {
        const url = paths.getAnonHtml.replace("{docId}", props.docId);
        return Request.get<iParams.oGetAnonHtml>(
            url
        );
    },
    downloadAnonymizeDocumentsResult: (params: iParams.iDownloadAnonymizeDocumentsResult) => {
        const url = paths.downloadAnonymizeDocumentsResult;
        return Request.post<iParams.oDownloadAnonymizeDocumentsResult>(
            url,
            params,
            {
                responseType: "blob"
            }
        );
    },
    getColoredPdf: (params: iParams.iGetColoredPdf) => {
        return Request.get<iParams.oGetColoredPdf>(
            paths.getColoredPdf(params.document_id),
            undefined,
            {
                responseType: "blob"
            }
        );
    },
    changeColoredPdfNodes: (params: iParams.iChangeColoredPdfNodes) => {
        const {anonymized_document_id, ...body} = params;
        return Request.post(
            paths.changeColoredPdfNodes(anonymized_document_id), 
            body
        );
    },
    getColoredPdfInfo: (params: iParams.iGetColoredPdfInfo) => Request.get<iParams.oGetColoredPdfInfo>(paths.getColoredPdfInfo(params.anonymized_document_id)),
    getAnonType: (params: iParams.iGetAnonType) => Request.get<iParams.oGetAnonType>(paths.getAnonType(params.anonymized_documents_ids))
};

import React from "react";
import ViewModeSwitcher from "./Modules/ViewModeSwitcher/ViewModeSwitcher";
import {useAppSelector, useAppDispatch} from "@root/Hooks";
import Actions from "@actions";
import BackLink from "./Modules/BackLink/BackLink";
import useDocument from "../../Hooks/useDocument";
import Toolbar from "@root/Components/Toolbar/Toolbar";
import DropdownMenu from "./Modules/DropdownMenu/DropdownMenu";
import styles from "./TopToolbar.module.less";
import usePageParams from "../../Hooks/useParams";

const TopToolbar = () => {
    const {anonymizationID} = usePageParams(); 
    const dispatch = useAppDispatch();
    const openedDocument = useAppSelector((state) => state.Project.openedDocument.data);
    const selectedMarkups = useAppSelector((state) => state.HTML.selectedMarkups);
    const addedEntities = useAppSelector((state) => state.HTML.entities.newEntities);
    const anonymizedHtml = useAppSelector((state) => state.Anonymize.anonymizedHtml.data);
    const documentState = useDocument();
    const anonymizationType = useAppSelector((state) => state.Anonymize.anonType.data?.anonymization_type); 
    
    if (!openedDocument || !documentState) return null;
    
    const downloadResult = () => {
        dispatch(Actions.Anonymize.downloadAnonymizeDocumentsResult({
            anonymized_documents_ids: [anonymizationID]
        }));
    };

    const downloadOriginal = () => {
        dispatch(Actions.Document.downloadOriginalDocument({
            documents_ids: [openedDocument.id]
        }));
    };

    return (
        <Toolbar.Wrapper className={styles.wrapper}>
            <Toolbar.Col>
                <>
                    <BackLink 
                        documentId={openedDocument.id}
                        projectId={openedDocument.project_id}
                        anonymizedDocumentId={anonymizationID}
                        anonymizationRequestId={anonymizedHtml?.anonymization_request_id || ""}
                        addedEntities={addedEntities}
                        selectedMarkups={selectedMarkups}/>
                    <Toolbar.Title title={openedDocument.document_name} />
                </>
            </Toolbar.Col>
            <Toolbar.Col colWrapElements>
                {
                    (anonymizationType !== "pdf_anonymization" && anonymizedHtml) && // Если разметка не на pdf и есть инфа о анонимизации html 
                    (
                        <ViewModeSwitcher
                            addedEntities={addedEntities}
                            anonymizationRequestId={anonymizedHtml.anonymization_request_id}
                            anonymizedDocumentId={anonymizationID}
                            documentId={openedDocument.id}  
                            selectedMarkups={selectedMarkups}
                            settings={documentState.settings}/>
                    )
                }
                <div className={styles.downloadButton}> 
                    <DropdownMenu
                        downloadOriginal={downloadOriginal}
                        downloadResult={downloadResult}
                    />
                </div>
            </Toolbar.Col>
        </Toolbar.Wrapper>
    );
};

export default TopToolbar;

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import Api from "@api";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Anonymize/types";

const GetColoredPdfInfo = function* (action: PayloadAction<iActions.GetColoredPdfInfo>) {
    try {
        const {data} = yield* call(Api.Anonymize.getColoredPdfInfo, action.payload);
        yield* put(Actions.Anonymize._getColoredPdfInfoSuccess(data));
    } catch (error) {
        yield* put(Actions.Anonymize._getColoredPdfInfoError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Anonymize.getColoredPdfInfo, GetColoredPdfInfo);
}

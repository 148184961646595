import React, {useMemo, useRef} from "react";
import TableFilters from "@root/Modules/TableFilters/TableFilters";
import {FilterDropdownProps} from "antd/es/table/interface";
import {useAppSelector} from "@root/Hooks";
import styles from "./useColumns.module.less";
import TableFilterParseStatus from "./../Modules/TableFilterParseStatus/TableFilterParseStatus";
import useGetMinColumnWidthForTable from "@root/Hooks/GetMinColumnWidthForTable/useGetMinColumnWidthForTable";
import DocumentBadge from "../Modules/DocumentBadge/DocumentBadge";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import getDate from "@root/Utils/Date";
import dataFilterMapper from "@root/Utils/Columns/dataFilterMapper";
import getFilterDataFunction from "@root/Utils/Columns/getFilterDataFunction";
import getStringStatus from "./Utils/getStringStatus";
import getAnonStringStatus from "./Utils/getAnonStringStatus";
import Dropdown from "./Modules/Dropdown/Dropdown";
import {Documents} from "@types";

type DocumentListItem = Documents.Item;

const useColumns = () => {
    const documents = useAppSelector((state) => state.Project.documents.data?.documents);
    const getFilterData = getFilterDataFunction({array: documents || []});
    const titleColumnRef = useRef<HTMLDivElement>(null);
    
    const documentNameFD = useMemo(() => getFilterData((item) => item.document_name), [documents]);
    const titleFD = useMemo(() => getFilterData((item) => item.meta_title || "-"), [documents]);
    const createdDateFD = useMemo(() => getFilterData((item) => getDate.full({date: item.created_date})), [documents]);
    const creationDateFD = useMemo(() => getFilterData((item) => getDate.full({date: item.meta_creation_date || "-"})), [documents]);
    const lastModifiedFD = useMemo(() => getFilterData((item) => getDate.full({date: item.meta_last_modified || "-"})), [documents]);
    const statusFD = ["Загружен", "В процессе", "Ошибка загрузки"];
    const anonStatusFD = ["Ошибка", "В процессе", "Не производилось", "Обезличен"];
    const anonCountFD = useMemo(() => getFilterData((item) => (item.anonymization_count ? item.anonymization_count.toString() : "0")), [documents]);

    return [
        {
            title: <div className={styles.statusTitle}>{""}</div>,
            render: (
                record: DocumentListItem
            ) => {
                if (record.overall_status === 0 || record.overall_status === 100) {
                    return (
                        <DocumentBadge status="loading"/>
                    );
                } else if (record.overall_status === 400) {
                    return (
                        <DocumentBadge status="error" />
                    );
                } else {
                    return (
                        <DocumentBadge status={"success"}/>
                    );
                }
            },
            filters: statusFD.map(dataFilterMapper),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilterParseStatus {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => {
                return getStringStatus(record.overall_status) === value;
            },
            width: 60,
            ellipsis: true,
            className: styles.statusColumn,
        },
        {
            title: "Название документа",
            dataIndex: "document_name",
            key: "document_name",
            filters: documentNameFD.map(dataFilterMapper),
            width: 250,
            render: (document_name: DocumentListItem["document_name"]) => 
                (
                    <span style={{
                        minWidth: useGetMinColumnWidthForTable("Название документа")
                    }}
                    className={styles.titleColumn}
                    ref={titleColumnRef}>
                        <Tooltip 
                            title={document_name}
                            placement="right">
                            {document_name}
                        </Tooltip>
                    </span>
                ),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => record.document_name === value,
            ellipsis: true,
        },
        {
            title: "Описание",
            dataIndex: ["meta_title"],
            key: "meta_title",
            showSorterTooltip: false,
            render: (title: DocumentListItem["meta_title"]) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Описание")}}>
                        {title || "-"}
                    </div>
                );
            },
            filters: titleFD.map(dataFilterMapper),
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => record.meta_title || "-" === value,
            ellipsis: true,
        },
        {
            title: "Дата загрузки",
            dataIndex: "created_date",
            key: "created_date",
            filters: createdDateFD.map(dataFilterMapper),
            sorter: true,
            showSorterTooltip: false,
            render: (created_date: DocumentListItem["created_date"]) =>
                (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Дата загрузки")}}>
                        {getDate.full({date: created_date})}
                    </div>
                ),
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => getDate.full({date: record.created_date}) === value,
            ellipsis: true,
        },
        {
            title: "Дата создания документа",
            dataIndex: ["meta_creation_date"],
            key: "meta_creation_date",
            render: (
                creation_date: DocumentListItem["meta_creation_date"]
            ) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Дата создания документа")}}>
                        {getDate.full({date: creation_date || "-"})}
                    </div>
                );
            },
            filters: creationDateFD.map(dataFilterMapper),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => getDate.full({date: record.meta_creation_date || "-"}) === value,
            ellipsis: true,
        },
        {
            title: "Дата изменения документа",
            dataIndex: ["meta_last_modified"],
            key: "meta_last_modified",
            render: (
                last_modified: DocumentListItem["meta_last_modified"]
            ) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Дата изменения документа")}}>
                        {getDate.full({date: last_modified || "-"})}
                    </div>
                );
            },
            filters: lastModifiedFD.map(dataFilterMapper),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => getDate.full({date: record.meta_last_modified || "-"}) === value,
            ellipsis: true,
        },
        {
            title: "Статус обезличивания",
            render: (
                record: DocumentListItem
            ) => {
                const anonStatus = record.anonymization_status;
                if (anonStatus === null || anonStatus === undefined || anonStatus === 0) {
                    return (
                        <div className={styles.anonStatusTitle} style={{minWidth: useGetMinColumnWidthForTable("Статус обезличивания")}}>
                            <DocumentBadge type="anonymization"/>
                            <span>Не производилось</span>
                        </div>
                    );
                } else if (anonStatus === 100) {
                    return (
                        <div className={styles.anonStatusTitle} style={{minWidth: useGetMinColumnWidthForTable("Статус обезличивания")}}>
                            <DocumentBadge status={"loading"} type="anonymization"/>
                            <span>В процессе</span>
                        </div>
                    );
                } else if (anonStatus === 400) {
                    return (
                        <div className={styles.anonStatusTitle} style={{minWidth: useGetMinColumnWidthForTable("Статус обезличивания")}}>
                            <DocumentBadge status={"error"} type="anonymization"/>
                            <span>Ошибка</span>
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.anonStatusTitle} style={{minWidth: useGetMinColumnWidthForTable("Статус обезличивания")}}>
                            <DocumentBadge status={"success"} type="anonymization"/>
                            <span>Обезличен</span>
                        </div>
                    );
                }
            },
            filters: anonStatusFD.map(dataFilterMapper),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilterParseStatus {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => getAnonStringStatus(record.anonymization_status) === value,
            ellipsis: true,
        },
        {
            title: "Обезличиваний",
            dataIndex: ["anonymization_count"],
            key: "anonymization_count",
            render: (
                anonymization_count: DocumentListItem["anonymization_count"]
            ) => {
                return (
                    <div style={{minWidth: useGetMinColumnWidthForTable("Обезличиваний")}}>
                        {anonymization_count ? anonymization_count.toString() : "0"}
                    </div>
                );
            },
            filters: anonCountFD.map(dataFilterMapper),
            showSorterTooltip: false,
            filterDropdown: (props: FilterDropdownProps) => {
                return <TableFilters {...props} />;
            },
            onFilter: (
                value: string | number | boolean,
                record: DocumentListItem
            ) => (record.anonymization_count ? record.anonymization_count.toString() : "0" === value.toString()),
            ellipsis: true,
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (_: DocumentListItem["id"], record: DocumentListItem) => {
                return <Dropdown
                    item={record}/>;
            },
        }

    ];
};

export default useColumns;

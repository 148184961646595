import {ButtonIcon} from "@root/Components/Controls";
import Tooltip from "@root/Components/Tooltip/Tooltip";
import React from "react";
import styles from "./ButtonChangeMode.module.less";
import classNames from "classnames";
import {LoadingOutlined} from "@ant-design/icons";
import useGetButtonData from "./Hooks/useGetButtonData";

type ButtonChangeModeProps = {
    type: Type,
    onClick?: () => void,
    isLoading?: boolean, 
    disabled?: boolean,
    tooltip: string,
    isEditing?: boolean
}

export enum Type {
    startMarkuping = "startMarkuping",
    save = "save",
    stop = "stop",
}

const ButtonChangeMode = ({
    type,
    onClick,
    isLoading = false,
    disabled = false,
    tooltip,
    isEditing
}: ButtonChangeModeProps) => {
    const buttonData = useGetButtonData(type);
    const click = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    if (!buttonData) return null;

    if (type === Type.startMarkuping) buttonData.style = `${classNames(buttonData.style, {[styles.buttonEditActive]: isEditing})}`;
    
    return (
        <Tooltip
            placement="right"
            lineTooltip={true}
            title={tooltip}>
            <ButtonIcon   
                size="middle"
                onClick={click}
                className={classNames(buttonData.style, {[styles.disabled]: disabled})}>
                {isLoading ? <LoadingOutlined /> : buttonData.icon}
            </ButtonIcon> 
        </Tooltip>
    );
};

export default ButtonChangeMode;

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import Api from "@api";
import {iActions} from "@redux/Projects/types";
import useNotification from "@root/Hooks/useNotification/useNotification";

const ChangeProjectInfo = function* (action: PayloadAction<iActions.changeProjectInfo>) {
    const {payload} = action;
    const notification = useNotification();

    try {
        yield* call(Api.Projects.changeProjectInfo, payload);
        yield* put(Actions.Projects.getProjects());
        yield* put(Actions.Projects.getSelectedProject({project_id: payload.id}));
        yield* put(Actions.Projects.setEditModal(false));
        notification({
            type: "info",
            message: "Информация о проекте изменена"
        });
    } catch (error) {
        yield* put(Actions.Projects._changeProjectInfoError());
        notification({
            type: "error",
            message: "При изменении информации о проекте произошла ошибка"
        });
    }
};

export default function* () {
    yield* takeLatest(Actions.Projects.changeProjectInfo, ChangeProjectInfo);
}

import Request from "../Request";
import {Projects} from "./types";
import Endpoints from "./endpoints";

export default {
    createProject: (params: Projects.iCreateProject) => {
        return Request.post<Projects.oCreateProject>(`${Endpoints.project.base}`, params);
    },
    getProjects: () => {
        return Request.get<Projects.oGetProjects>(`${Endpoints.project.base}`);
    },
    deleteProject: (params: Projects.iDeleteProject) => {
        return Request.delete<void>(`${Endpoints.project.base}/${params.id}`);
    },
    changeProjectInfo: (params: Projects.iChangeProjectInfo) => {
        const url =  Endpoints.project.changeInfo.replace("{id}", params.id);
        return Request.patch(
            url,
            {
                title: params.title,
                description: params.description
            }
        );
    }
};

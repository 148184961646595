import {ColoredPdfNodeForRender} from "@root/Types/AnonymizationPdf";

type getScaledVariablesForPrintProps = {
    item: ColoredPdfNodeForRender
    scale: number
}

const getScaledVariablesForPrint = (props: getScaledVariablesForPrintProps) => {
    const {item, scale} = props;
    const {x, y, height, width} = item;
    return {
        x: x * scale,
        y: y * scale,
        width: width * scale,
        height: height * scale
    };
};

export default getScaledVariablesForPrint;

import React, {useEffect, useState} from "react";
import styles from "./Dropdown.module.less";
import {Popover} from "antd";
import {ButtonIcon} from "@root/Components/Controls";
import {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import {Documents} from "@types";
import {useAppDispatch, useAppSelector} from "@root/Hooks";
import Actions from "@root/Redux/actions";
import AnonymizedDocumentsDropdown from "@root/Modules/AnonymizedDocumentsDropdown/AnonymizedDocumentsDropdown";

type DropdownProps = {
    item: Documents.Item;
}

const Dropdown = (props: DropdownProps) => {
    const [popover, setPopover] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {item} = props;
    const project = useAppSelector((state) => state.Projects.selectedProject.data);
    const [loading, setLoading] = useState<boolean>(false);
    const anonymizedDocuments = useAppSelector((state) => state.Document.anonymizedDocuments);
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const handlePopoverVisible = (visible: boolean) => {
        setPopover(visible);
    };

    const onOpenChange = (visible: boolean) => {
        if (visible) {
            setLoading(true);
            dispatch(Actions.Document.getAnonymizedDocuments({
                document_id: item.id,
                onSuccess: () => {
                    if (isMounted) {
                        handlePopoverVisible(visible);
                        setLoading(false);
                    }

                },
                onError: () => {
                    isMounted && setLoading(false);
                }
            }));
        }
        if (!visible) {
            dispatch(Actions.Document.eraseAnonymizedDocuments());
            handlePopoverVisible(visible);
        }
    };

    useEffect(() => {
        return () => setIsMounted(false);
    }, []);

    return (
        <Popover
            key="more"
            content={<AnonymizedDocumentsDropdown projectId={project?.id} documentId={item.id}/>}
            trigger="click"
            open={popover}
            onOpenChange={onOpenChange}
            placement="bottomLeft"
            overlayClassName={styles.popover}>
            <ButtonIcon
                type="text"
                disabled={anonymizedDocuments.fetching}
                icon={
                    loading ? <LoadingOutlined />
                        : <MoreOutlined 
                            style={{
                                fontSize: 20,
                                lineHeight: 0,
                                transform: "rotate(90deg)"
                            }}/>
                }
                onClick={(e) => e.stopPropagation()}
                className={styles.button}
            />
        </Popover>
    );
};

export default Dropdown;

import {takeLatest, call, put} from "typed-redux-saga";
import Actions from "@actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {iActions} from "@redux/Projects/types";
import Api from "@api";
import useNotification from "@root/Hooks/useNotification/useNotification";

const DeleteProject = function* (action: PayloadAction<iActions.deleteProject>) {
    const {payload} = action;
    const notification = useNotification();
    
    try {
        yield* call(Api.Projects.deleteProject, payload);
        yield* put(Actions.Projects._deleteProjectSuccess(payload));
        yield* put(Actions.Projects.setDeleteModal(false));
        notification({
            type: "info",
            message: "Проект удален"
        });
    } catch (error) {
        notification({
            type: "error",
            message: "При удалении проекта произошла ошибка"
        });
        yield* put(Actions.Projects._deleteProjectError());
    }
};

export default function* () {
    yield* takeLatest(Actions.Projects.deleteProject, DeleteProject);
}
